/* You can add global styles to this file, and also import other style files */
@import url("https://use.typekit.net/unj2nia.css");
@import url("https://use.typekit.net/unj2nia.css");

html,
body {
  min-width: 800px;

  //   ::-webkit-scrollbar {
  //     display: none;
  // }
  @media (max-width: 780px) {
    min-width: 100%;
  }
}

html {
  font-family: trade-gothic-next !important;
}

body {
  // font-family: trade-gothic-next, sans-serif !important;
  // font-weight: 400;
  // font-style: normal;

  font-family: trade-gothic-next !important;
  font-weight: 400;
  font-style: normal;

  font-size: 18px;
  color: #2a233d;
}

.container {
  padding: 0px !important;
}

li {
  list-style-position: inside;
}

p {
  line-height: 1.5;
  font-size: 1.3rem !important;

  &.small {
    font-size: 1.1rem !important
  }

  &.no-margin {
    margin: 0;
  }
}

a {
  color: var(--bs-link-color) !important;
  text-decoration: underline !important;
}

.fa-xlg {
  font-size: 1.5em;
  line-height: .75em;
  vertical-align: -15%;
}

.flex-v {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.flex-h {
  display: flex;

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.space-b {
    justify-content: space-between;
  }
}

.modal-container {
  margin: 50px;

  h6 {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }
}

// @import '~@angular/material/theming';
//@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

// $app-typography: mat-typography-config(
//   $font-family: 'Muli, Roboto, "Helvetica Neue", sans-serif,',
//   $body-1: mat-typography-level(18px, 20px, 400)
// );

// @include mat-core($app-typography);

// background: #26a69a;
.modal {
  // background-color: transparent !important;
  // width: 100% !important;
  // max-height: 100% !important;

  // .modal-dialog {
  //   top: 30%;
  // }
}

.divider {
  width: 100%;
  max-width: 780px;
  padding: 18px 0px 10px;
}

section {
  max-width: none;
}

h5 {
  font-weight: 700 !important;
  font-style: normal !important;

  font-size: 1.5rem !important;
}

h2 {
  text-align: center;
}

button {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0px 16px;
  border-radius: 4px;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: visible;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  border: 1px solid #ced4da !important;
  padding: 0px 0.8rem;
}

cite {
  font-style: normal;
  position: relative;
  font-size: 0.8rem;

  a {
    position: absolute;
    top: -12px;
    left: -3px;
  }
}

.wrapper-center {
  display: flex;
  justify-content: center;
  position: relative;
  height: auto;
  min-height: 700px;
  //min-height: 86vh;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~swiper/swiper-bundle";

.swiper-container {
  // width: 100%;
  max-width: 880px;
  height: calc(100% + 42px);
}

.swiper-slide {
  //background: radial-gradient(circle, #e8e0fc 0%, #d8caf9 39%, #b2a0dc 95%);
}

.swiper-button-next,
.swiper-button-prev {
  color: #3c253a;
  top: calc(50% - 21px);
}

.swiper-pagination-bullet {
  border: 1px solid black;
  background: #3c253a;
  height: 12px;
  width: 12px;

  @media screen and (max-width: 790px) {
    border: 1px solid white;
    background: white;
  }
}

.swiper-zoom-container>img,
.swiper-zoom-container>svg,
.swiper-zoom-container>canvas {
  max-width: 780px;
  max-height: 519px;
}

.checkbox {
  .mat-checkbox-layout .mat-checkbox-label {
    //max-width: 100px;
  }
}

.mat-tab-label .mat-tab-label-content {
  height: 100%;
}

.mat-tab-label {
  min-width: 142px !important;
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.9 !important;
}

.mat-tab-body-content {
  margin-top: 30px;
}

.mat-tab-header {
  // position: fixed !important;
  background: white;
  z-index: 90;
  max-width: fit-content;
  width: 100%;
  position: sticky !important;
  top: 106px;
  margin: 0 auto;

  @media screen and (max-width: 790px) {
    top: 86px;
  }
}

.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden !important;
}

mat-tab-group {
  width: 100%;
}

.btn-primary {
  background: #26a69a;
  border-color: #26a69a;

  &:disabled {
    background: #26a69a;
    border-color: #26a69a;
  }
}

.mat-tab-label-active {
  background: white;
}

.mat-tab-label .mat-tab-label-content {
  width: 100%;
}

.mat-tab-label {
  padding: 0px !important;
  font-size: 1.1rem;
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

/* Sortable table demo */

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: "";
  display: block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC") no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.btn.btn-primary {
  &:hover {
    background: #3c253a !important;
  }
}

/* Filtering table demo */
// ngbd-table-filtering span.ngb-highlight {
//   background-color: yellow;
// }

// /* Complete table demo */
// ngbd-table-complete span.ngb-highlight {
//   background-color: yellow;
// }

ngb-carousel .picsum-img-wrapper {
  position: relative;
  height: 0;
  padding-top: 55%;
  /* Keep ratio for 900x500 images */
}

ngb-carousel .picsum-img-wrapper>img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// ngb-carousel {
//   // left: 50%;
//   width: fit-content;
//   transform: transform;
//   transform: translate(-50%, 0);
// }
.mat-card {
  font-family: trade-gothic-next, sans-serif !important;
  padding: 50px 20px !important;

  @media screen and (max-width: 790px) {
    padding-top: 40px !important;
  }

  &.mat-card-sp {
    padding: 16px !important;
  }
}

.mat-tab-group {
  font-family: trade-gothic-next, sans-serif !important;
}

.mat-card-title {
  text-align: center;
}

input,
button,
select,
optgroup,
textarea {
  font-family: trade-gothic-next, sans-serif !important;
}

.filterBy {
  color: black;
  font-size: 16px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: indigo;
}

.main-container-wrapper {
  padding: 50px 0px 0px;

  @media screen and (max-width: 431px) {
    padding: 40px 0px !important;
  }
}

.form-wrapper {
  background: rgba(176, 196, 222, 0.2);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 20px;

  @media screen and (max-width: 790px) {
    padding: 40px 20px;
  }
}

@media screen and (min-width: 791px) {
  .s-screens {
    display: none !important;
  }

  .m-screens {
    display: none !important;
  }
}

@media screen and (max-width: 431px) {
  .l-screens {
    display: none !important;
  }
}


@media screen and (max-width: 790px) {
  .l-screens {
    display: none !important;
  }
}

p {
  margin-bottom: 0.5rem !important;
}